import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
// import {
//   listenForNotifications,
//   markNotificationAsRead,
//   fetchNotifications,
// } from '../../../../common/service/realtimeService';
import Logo from '../../../assets/images/chinook_blue.png';
import cwt_Logo from '../../../assets/images/cwt.png';
// import chinook_cb_logo from '../../../assets/images/chinook_cb.png';
// import DXP_logo from '../../../assets/images/DXP2.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getDatabase, ref, set, update, onValue } from 'firebase/database';
import firebaseApp from '../../../service/realtimeConfig';

import { AccountCircle } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { navItems } from './utils';
import { useMsal } from '@azure/msal-react';

const drawerWidth = 210;

const settings = ['Logout'];

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#F3F4F6FF',
  color: 'Black',
  boxShadow: 'none',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ResponsiveAppBar: React.FC<{
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}> = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = React.useState<any>([]);
  const [newNotificationsCount, setNewNotificationsCount] = React.useState(0);
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  // ///testtt///////////
  // const database = getDatabase(firebaseApp);
  // const pid = '3093';
  // const databaseRef = ref(database, `/valves/${pid}/`);
  // const setData = async (
  //   target: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
  // ) => {
  //   // const tempValue = document.getElementById("temp-field")?.;
  //   // const presValue = document.getElementById("pres-field")?.nodeValue;
  //   console.log(target.id);
  //   if (target.id === 'temp-field') {
  //     await update(databaseRef, { temp: target.value });
  //   } else {
  //     await update(databaseRef, { pres: target.value });
  //   }
  // };
  // const addValve = async () => {
  //   const pid2 = '3094';
  //   const databaseRef2 = ref(database, `/valves/${pid2}`);
  //   await set(databaseRef2, { temp: '150' });
  // };
  // onValue(
  //   databaseRef,
  //   (sanpShot) => {
  //     console.log('testttt reallll', sanpShot.val());
  //   },
  //   { onlyOnce: false },
  // );

  // // Function to listen for new notifications
  // React.useEffect(() => {
  //   const user = accounts[0].name;
  //   // console.log('ussuu', user);

  //   // Real-time listener for notifications
  //   console.log('getting notifications');

  //   fetchNotifications((notification: any) => {
  //     // Check if the current user is one of the receivers
  //     console.log('nottttt', notification);

  //     const isReceiver = notification.receivers.some(
  //       (receiver: any) => receiver.name === user,
  //     );
  //     if (isReceiver) {
  //       setNotifications((prevNotifications: any) => [
  //         ...prevNotifications,
  //         notification,
  //       ]);

  //       // Count new notifications that are unread
  //       const unreadNotifications = notification.receivers.filter(
  //         (receiver: any) => receiver.userId === user && !receiver.read,
  //       );
  //       setNewNotificationsCount(
  //         (prevCount) => prevCount + unreadNotifications.length,
  //       );
  //     }
  //   });

  //   // Cleanup the listener on component unmount
  //   // return () => unsubscribe();
  // }, [accounts]);

  // const markAsRead = (notificationId: any) => {
  //   const user = accounts[0].name;
  //   markNotificationAsRead(notificationId, user);
  //   setNotifications((prevNotifications: any) =>
  //     prevNotifications.map((notification: any) =>
  //       notification.id === notificationId
  //         ? {
  //             ...notification,
  //             receivers: notification.receivers.map((receiver: any) =>
  //               receiver.userId === user
  //                 ? { ...receiver, read: true }
  //                 : receiver,
  //             ),
  //           }
  //         : notification,
  //     ),
  //   );
  // };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAndRedirect = () => {
    instance.logoutRedirect();
    navigate('/');
  };

  return (
    <AppBar position="relative" open={open}>
      <Toolbar
        variant="dense"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box display="flex">
          {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton> */}
          <img
            src={Logo}
            alt="ChinookDT"
            style={{ height: '48px', width: '90px' }}
          />
          <img
            src={cwt_Logo}
            alt="ChinookDT"
            style={{ height: '35px', width: 'auto' }}
          />
        </Box>

        <Box></Box>

        <Box sx={{ display: 'flex', gap: '15px' }}>
          {accounts?.length ? (
            navItems.map((item, index) => (
              <Button
                key={index}
                href={item.link}
                // variant="text"
                // startIcon={item?.icon}
                // color:"#3f429a"
                sx={{ textTransform: 'none', fontSize: '0.875rem' }}
              >
                {item.label}
                {/* <NotificationsIcon /> */}
              </Button>
            ))
          ) : (
            <></>
          )}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={logoutAndRedirect}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
